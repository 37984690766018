<div *ngIf="imageUrls != null && imageUrls.length > 0">
    <p-panel #panel styleClass="pro-panel panel-no-padding">
  
        <ng-template pTemplate="header">
          <span>Anexar arquivo</span>
        </ng-template>
    
        <form class="pro-form cadastrar-visita not-inline" [formGroup]="form">
            
            <div class="grid w-full m-0">
                <div class="pro-form-item nucleo-urbano col-12 required">
                    <p-dropdown styleClass="pro-inputtext align-items-center" formControlName="nucleoUrbano" [options]="nucleosUrbanosOffline"
                        placeholder="Núcleo Urbano" optionValue="id" inputId="nucleoUrbano" appendTo="body" [required]="true">
                        <ng-template pTemplate="selectedItem" let-nucleoUrbano>
                            <div>{{nucleoUrbano.nome}}</div>
                        </ng-template>
            
                        <ng-template pTemplate="item" let-nucleoUrbano>
                            <div>{{nucleoUrbano.nome}}</div>
                        </ng-template>
            
                        <ng-template pTemplate="empty">
                            <div>Nenhum núcleo urbano encontrado.</div>
                        </ng-template>
                    </p-dropdown>

                    <small class="pro-feedback violation" *ngIf="submitted && form.controls['nucleoUrbano'].errors?.['required']">O núcleo urbano é obrigatório</small>
                </div>

                <div class="pro-form-item lote col-12 required">
                    <p-dropdown styleClass="pro-inputtext align-items-center" formControlName="lote" [options]="lotesOffline"
                        placeholder="Lote" optionValue="id" inputId="lote" appendTo="body" [required]="true">
                        <ng-template pTemplate="selectedItem" let-lote>
                            <div>{{ 'Q' + (lote.quadraNome ? lote.quadraNome : lote.quadra) + '/L' + lote.numero}}</div>
                        </ng-template>
            
                        <ng-template pTemplate="item" let-lote>
                            <div>{{ 'Q' + (lote.quadraNome ? lote.quadraNome : lote.quadra) + '/L' + lote.numero}}</div>
                        </ng-template>
            
                        <ng-template pTemplate="empty">
                            <div>Nenhum lote encontrado.</div>
                        </ng-template>
                    </p-dropdown>

                    <small class="pro-feedback violation" *ngIf="submitted && form.controls['lote'].errors?.['required']">O lote é obrigatório</small>
                </div>

                <div *ngIf="form.controls['lote'].enabled" class="pro-form-item destino-arquivo col-12 required">
                    <p-dropdown styleClass="pro-inputtext align-items-center" [options]="destinosArquivo" [required]="true"
                                placeholder="Destino do arquivo" inputId="destinoArquivo" appendTo="body" formControlName="destinoArquivo">
                        <ng-template pTemplate="selectedItem" let-destinoDocumento>
                            <span class="ml-3">{{destinoDocumento.label}}</span>
                        </ng-template>

                        <ng-template pTemplate="item" let-destinoDocumento>
                            <span class="ml-3">{{destinoDocumento.label}}</span>
                        </ng-template>

                        <ng-template pTemplate="empty">
                            <div>Nenhum item encontrado.</div>
                        </ng-template>
                    </p-dropdown>

                    <small class="pro-feedback violation" *ngIf="submitted && form.controls['destinoArquivo'].errors?.['required']">O destino do documento é obrigatório</small>
                </div>

                <div *ngIf="form.controls['visita'].enabled" class="pro-form-item visita col-12 required">
                    <p-dropdown styleClass="pro-inputtext align-items-center" formControlName="visita" [options]="visitaRequests"
                        placeholder="Visita" optionValue="key" inputId="visita" appendTo="body" [required]="true">
                        <ng-template pTemplate="selectedItem" let-visita>
                            <div>{{visita.agente + ' - ' + (visita.dataHoraVisita | date: 'dd/MM/yyyy HH:mm' : 'UTC-3')}}</div>
                        </ng-template>
            
                        <ng-template pTemplate="item" let-visita>
                            <div>{{visita.agente + ' - ' + (visita.dataHoraVisita | date: 'dd/MM/yyyy HH:mm' : 'UTC-3')}}</div>
                        </ng-template>
            
                        <ng-template pTemplate="empty">
                            <div>Nenhuma visita encontrada.</div>
                        </ng-template>
                    </p-dropdown>

                    <small class="pro-feedback violation" *ngIf="submitted && form.controls['visita'].errors?.['required']">A visita é obrigatória</small>
                </div>

                <div *ngIf="form.controls['interessado'].enabled" class="pro-form-item interessado col-12 required">
                    <p-dropdown styleClass="pro-inputtext align-items-center" formControlName="interessado" [options]="interessadosOffline"
                        placeholder="Interessado" inputId="interessado" appendTo="body" [required]="true">
                        <ng-template pTemplate="selectedItem" let-interessado>
                            <div>{{interessado.nome}}</div>
                        </ng-template>
            
                        <ng-template pTemplate="item" let-interessado>
                            <div>{{interessado.nome}}</div>
                        </ng-template>
            
                        <ng-template pTemplate="empty">
                            <div>Nenhum interessado encontrado.</div>
                        </ng-template>
                    </p-dropdown>

                    <small class="pro-feedback violation" *ngIf="submitted && form.controls['interessado'].errors?.['required']">O interessado é obrigatório</small>
                </div>

            </div>
            
            <div class="grid w-full m-0">
                <div class="pro-form-item imagens col-12">
                    <h3 style="margin-bottom: 0.5rem;">Imagens</h3>
                
                    <div class="flex grid w-full m-0">
                        <ng-container *ngFor="let control of imagesFormArrayControls; let i = index;">
                            <div [ngClass]="{ 'col-12': imageUrls.length === 1, 'col-6': imageUrls.length === 2, 'col-4': (imageUrls.length >= 3) }"
                                    class="shared-image" [formGroup]="control">
                                <div>Nome: {{control.value.filename}}</div>
                                <div *ngIf="form.controls['interessado'].enabled">
                                    <div>Tipo do Documento</div>
                                    <div class="mt-2 mb-2">
                                        <p-dropdown #gruposDropdown styleClass="pro-inputtext align-items-center" [options]="gruposDocumentosInteressado" (onChange)="onChangeGrupoDocumento(gruposDropdown.value)"
                                        placeholder="Grupo de documento" inputId="grupoDocumento" appendTo="body">
                                            <ng-template pTemplate="item" let-grupoDocumento>
                                                <span class="ml-3">{{grupoDocumento.label}}</span>
                                            </ng-template>

                                            <ng-template pTemplate="selectedItem" let-grupoDocumento>
                                                <span class="ml-3">{{grupoDocumento.label}}</span>
                                            </ng-template>

                                            <ng-template pTemplate="empty">
                                                <div>Nenhum grupo de documento encontrado.</div>
                                            </ng-template>
                                        </p-dropdown>

                                        <small class="pro-feedback violation" *ngIf="submitted && form.controls['tipoDocumento'] && form.controls['tipoDocumento'].errors?.['required'] && gruposDropdown.value == null">O grupo do documento é obrigatório</small>
                                    </div>

                                    <div class="mt-2 mb-2">
                                        <p-dropdown *ngIf="documentosInteressado && documentosInteressado.length > 0" styleClass="pro-inputtext align-items-center" [options]="documentosInteressado"
                                            placeholder="Tipo do documento" inputId="tipoDocumento" appendTo="body" formControlName="tipoDocumento">
                                            <ng-template pTemplate="item" let-tipoDocumento>
                                                <span class="ml-3">{{tipoDocumento.label}}</span>
                                            </ng-template>
    
                                            <ng-template pTemplate="selectedItem" let-tipoDocumento>
                                                <span class="ml-3">{{tipoDocumento.label}}</span>
                                            </ng-template>
    
                                            <ng-template pTemplate="empty">
                                                <div>Nenhum tipo de documento encontrado.</div>
                                            </ng-template>
                                        </p-dropdown>

                                        <small class="pro-feedback violation" *ngIf="submitted && form.controls['tipoDocumento'] && form.controls['tipoDocumento'].errors?.['required']">O tipo do documento é obrigatório</small>
                                    </div>
                                </div>

                                <!-- <div *ngIf="control.value.type && control.value.type === 'application/pdf'">
                                    <embed [style]="{ 'width': '100%', 'height': '100%', 'min-height': '1000px'}" title="PDF File" [src]="control.value.imageUrl | safe"/>
                                </div>

                                <div *ngIf="control.value.type && control.value.type.includes('image');">
                                    <img style="width: 100%;" src={{control.value.imageUrl}} />
                                </div> -->
                            </div>
                        </ng-container>
                    </div>
                </div>            
            </div>
        
            <div class="p-2 pro-form-submit">
                <!-- <button class="pro-button tertiary" pButton type="button" (click)="cancelar()">Cancelar</button> -->
                <!-- O $event.preventDefault() faz com que os dados passados na URL não sejam interpretados como parâmetros, evitando
                    que seja adicionado um ponto de interrogação à URL quando offline (que faz com que a página não seja encontrada) -->
                <button class="pro-button w-full justify-content-center " pButton type="submit" [disabled]="submitted && !form.valid" (click)="$event.preventDefault(); save()">Anexar arquivo</button>
            </div>
    
        </form>
    
      </p-panel>
</div>

<div *ngIf="imageUrls == null || imageUrls.length === 0">
    <h3>No Images Loaded</h3>
</div>

<p-blockUI [blocked]="sendingRequest || loadingNucleosUrbanosOffline || loadingLotesOffline || loadingInteressadosOffline || loadingVisitaRequests || sendingFiles">
    <ng-template pTemplate="content">
      <p-progressSpinner></p-progressSpinner>
    </ng-template>
</p-blockUI>
