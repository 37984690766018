import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable()
export class ApiAddressInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.get("skip")) {
      const headers = request.headers.delete('skip').delete('authorization');
      const apiReq = request.clone({ headers: headers });
      
      return next.handle(apiReq);
    }
      

    const apiReq = request.clone({ url: `${environment.API_URL}/${request.url}` });
    return next.handle(apiReq);
  }

}
