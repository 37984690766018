<main>
    <p-toast position="bottom-center" [breakpoints]="{'500px': { 'width': '100%' }}">
        <ng-template pTemplate="message" let-message>
            <div *ngIf="message.severity === 'error'" class="w-full">
                <div class="w-full">
                    <div class="inline">
                        <fa-icon [icon]="['fas', 'circle-xmark']"></fa-icon>
                        <h4 [style]="{ 'display': 'inline', 'margin-left': '0.5rem' }">{{message.summary}}. </h4>
                    </div>
                    <div class="inline">
                        <span> {{message.detail}}</span>
                    </div>
                </div>
            </div>

            <div *ngIf="message.severity === 'success'" class="w-full">
                <div class="w-full">
                    <div class="inline">
                        <fa-icon [icon]="['fas', 'check-circle']"></fa-icon>
                        <h4 [style]="{ 'display': 'inline', 'margin-left': '0.5rem' }">{{message.summary}}. </h4>
                    </div>
                    <div class="inline">
                        <span> {{message.detail}}</span>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>

    <app-modal-update></app-modal-update>

    <app-header></app-header>
    <app-current-environment></app-current-environment>
    <div class="app-router"><router-outlet></router-outlet></div>
    <app-footer></app-footer>
</main>