import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CommonModule, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DEFAULT_CONFIG, Driver, NgForageOptions } from 'ngforage';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { CoreModule } from './core/core.module';
import { LayoutModule } from './core/layout/layout.module';
import { environment } from 'src/environments/environment';

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';
import { FileUploadModule } from 'primeng/fileupload';
import { AppErrorHandler } from './app-error-handler.interceptor';
import { ApiAddressInterceptor } from './core/interceptors/api-address-interceptor.interceptor';
import { ApiErrorInterceptor } from './core/interceptors/api-error-interceptor.interceptor';
import { SharedModule } from './shared/shared.module';
import { faAngleDoubleRight, faCheck, faCheckCircle, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { ServiceWorkerModule } from '@angular/service-worker';
import { OfflineInterceptor } from './core/interceptors/offline.interceptor';

registerLocaleData(localePt);

@NgModule({

  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    LayoutModule,

    ToastModule,
    FileUploadModule,

    KeycloakAngularModule,
    FontAwesomeModule,
    NgxMaskDirective,
    NgxMaskPipe,
    HttpClientModule,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   // enabled: !isDevMode(),
    //   // Register the ServiceWorker as soon as the application is stable
    //   // or after 30 seconds (whichever comes first).
    //   enabled: true,
    //   registrationStrategy: 'registerImmediately'
    // })
    ServiceWorkerModule.register('extended-service-worker.js', {
      // enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      enabled: true,
      registrationStrategy: 'registerWhenStable:5000'
    })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: ApiErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiAddressInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: OfflineInterceptor, multi: true },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    // One way of configuring ngForage
    {
      provide: DEFAULT_CONFIG,
      useValue: {
        name: 'local-store-coleta',
        driver: [ // defaults to indexedDB -> webSQL -> localStorage
          Driver.INDEXED_DB,
          Driver.LOCAL_STORAGE
        ]
      } as NgForageOptions
    },
    provideNgxMask(),
    HttpClient,
    MessageService,
    DialogService
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faCheck, faXmark, faCheckCircle, faXmarkCircle, faAngleDoubleRight);
  }
}

function initializeKeycloak(keycloak: KeycloakService) {

  const token : string | undefined = localStorage.getItem('kc_token') == null ? undefined : String(localStorage.getItem('kc_token'));
  const refreshToken = localStorage.getItem('kc_refreshToken') == null ? undefined : String(localStorage.getItem('kc_refreshToken'));

  return () =>
    keycloak.init({
      config: {
        url: environment.KEYCLOAK_URL,
        realm: environment.KEYCLOAK_REALM,
        clientId: environment.KEYCLOAK_CLIENT,
      },
      initOptions: {
        checkLoginIframe: false,
        token: token,
        refreshToken: refreshToken
      },
      enableBearerInterceptor: true,
      loadUserProfileAtStartUp: true
    })
    .then((auth) => {
      if (!auth && !token && !refreshToken) {
        keycloak.login({
          scope: 'openid offline_access',
        }).catch((e) => console.error(e));
      } else {
        const tokenParsed = keycloak.getKeycloakInstance().tokenParsed;

        if (tokenParsed) {
          const currentUsername = tokenParsed?.['preferred_username'];
          const currentTime = new Date().getTime();

          const oldUsername = localStorage.getItem('currentUser');
          const oldTime = localStorage.getItem('currentUserTimeStamp');

          if (oldUsername == null || oldTime == null) {
            localStorage.setItem('currentUser', currentUsername);
            localStorage.setItem('currentUserTimeStamp', currentTime.toString());
          }
            
        }

      }
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      if (keycloak.getKeycloakInstance().token != null)
        localStorage.setItem('kc_token', String(keycloak.getKeycloakInstance().token));
      if (keycloak.getKeycloakInstance().refreshToken != null)
        localStorage.setItem('kc_refreshToken', String(keycloak.getKeycloakInstance().refreshToken));
    });
}
