import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { PageForbiddenComponent } from './page-forbidden/page-forbidden.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ShareTargetComponent } from './share-target/share-target.component';
import { PageConfirmUsernameComponent } from './page-confirm-username/page-confirm-username.component';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PanelModule } from 'primeng/panel';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DropdownModule } from 'primeng/dropdown';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    HomeComponent,
    PageForbiddenComponent,
    PageNotFoundComponent,
    ShareTargetComponent,
    PageConfirmUsernameComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
  
    SharedModule,

    FontAwesomeModule,

    InputTextModule,
    ButtonModule,
    BlockUIModule,
    ProgressSpinnerModule,
    PanelModule,
    DropdownModule,

    CommonModule
  ]
})
export class PagesModule { }
