import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MenubarModule } from 'primeng/menubar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { CurrentEnvironmentComponent } from './current-environment/current-environment.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    CurrentEnvironmentComponent
  ],
  imports: [
    CommonModule,
    MenubarModule,
    ButtonModule,
    FontAwesomeModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    CurrentEnvironmentComponent
  ]
})
export class LayoutModule { }
