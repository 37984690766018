import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, fromEvent, map, merge, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NetworkStatusService {

  isOnline$: Observable<boolean>;

  private isOnlineSubject: BehaviorSubject<boolean> = new BehaviorSubject(navigator.onLine);
  isOnlineSubject$ = this.isOnlineSubject.asObservable();

  private serverStatusSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  serverStatusSubject$ = this.serverStatusSubject.asObservable();

  serverStatus: boolean = false;

  status: boolean = false;

  constructor(private http: HttpClient) {
    this.isOnline$ = merge(
      // this.serverStatusSubject$,
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(map(() => true)),
      fromEvent(window, 'offline').pipe(map(() => false))
    );

    this.isOnline$.subscribe(value => {
      this.status = value;
      this.isOnlineSubject.next(value);
    });

    // window.setInterval(() => {
    //   this.getConnectionToServer();
    // }, 2000);

  }

  public getConnectionToServer(): any {
    const url = `${environment.API_URL}/public`;

    let headers = new HttpHeaders()
    .set('skip', 'true');

    const httpOptions: Object = {
      headers: headers,
      responseType: 'text'
    };

    this.http.get<any>(url, httpOptions)
    .subscribe({
      next: () => {
        this.serverStatus = true;

        this.serverStatusSubject.next(this.serverStatus);
      },
      error: (error: any) => {
        this.serverStatus = false;
        this.serverStatusSubject.next(this.serverStatus);
        
        console.log(error);
      }
    });
  }

  public getConnectionStatus(): boolean {
    return this.status;
  }

}
