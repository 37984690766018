export enum Role {
  "agente",
  "agente-terceirizado",
  "agentes-buscar",
  "agentes-cadastrar",
  "agentes-editar",
  "agentes-excluir",
  "agentes-listar",
  "analista-procempa",
  "anotacoes-cadastrar",
  "anotacoes-excluir",
  "anotacoes-listar",
  "baixar-lista-legitimados",
  "buscar-modelo",
  "buscar-modelo-proprio",
  "buscar-produtividade",
  "buscar-titulacao",
  "cadastrar-modelo",
  "despachante",
  "documentacao-unificada-lote",
  "documentacao-unificada-unidade",
  "editar-titulacao",
  "empresascoleta-cadastrar",
  "empresascoleta-excluir",
  "empresascoleta-listar",
  "enderecosinformais-cadastrar",
  "enderecosinformais-excluir",
  "enderecosinformais-listar",
  "enviar-documento-sei",
  "etiquetas-cadastrar",
  "etiquetas-excluir",
  "etiquetas-listar",
  "etiquetas-publicas-cadastrar",
  "gerar-documento-sei",
  "gerar-lista-legitimados",
  "gerente-terceirizado",
  "informacao-tecnica",
  "informacao-tecnica-cadastro",
  "interessados-buscar",
  "interessados-cadastrar",
  "interessados-documentos-baixar",
  "interessados-documentos-editar",
  "interessados-documentos-enviar",
  "interessados-documentos-excluir",
  "interessados-documentos-listar",
  "interessados-editar",
  "interessados-editar-apto-titulacao",
  "interessados-epgm",
  "interessados-excluir",
  "interessados-listar",
  "interessados-telefone-cadastrar",
  "interessados-telefone-excluir",
  "liderancas-cadastrar",
  "liderancas-editar",
  "liderancas-excluir",
  "listar-modelo",
  "lotes-agentes-atribuir",
  "lotes-agentes-listar",
  "lotes-agentes-remover",
  "lotes-buscar",
  "lotes-cadastrar",
  "lotes-cadastrar-multiplos",
  "lotes-cadastrar-multiplos-cancelar",
  "lotes-documentos-baixar",
  "lotes-documentos-enviar",
  "lotes-documentos-excluir",
  "lotes-documentos-listar",
  "lotes-editar",
  "lotes-editar-apto-titulacao",
  "lotes-editar-endereco",
  "lotes-editar-interesse-regularizacao",
  "lotes-editar-modalidade",
  "lotes-editar-tipo",
  "lotes-editar-tipo-area",
  "lotes-editar-tipo-registro",
  "lotes-epgm",
  "lotes-etiquetas-atribuir",
  "lotes-etiquetas-excluir",
  "lotes-excluir",
  "lotes-fracionar",
  "lotes-listar",
  "lotes-titulo",
  "lotes-unificar",
  "modelos",
  "nucleosurbanos-buscar",
  "nucleosurbanos-cadastrar",
  "nucleosurbanos-editar",
  "nucleosurbanos-excluir",
  "nucleosurbanos-listar",
  "quadras-cadastrar",
  "quadras-listar",
  "recriar-processo-sei",
  // "sipi-admin",
  // "sipi-operador",
  "unidades-buscar",
  "unidades-cadastrar",
  "unidades-editar",
  "unidades-editar-apto-titulacao",
  "unidades-editar-interesse-regularizacao",
  "unidades-editar-modalidade",
  "unidades-editar-tipo-registro",
  "unidades-editar-tipo-titulacao",
  "unidades-excluir",
  "unidades-listar",
  "usuario-comum",
  "usuarios-listar",
  "visitas-buscar",
  "visitas-cadastrar",
  "visitas-documentos-baixar",
  "visitas-documentos-enviar",
  "visitas-documentos-excluir",
  "visitas-documentos-listar",
  "visitas-editar",
  "visitas-excluir",
  "visitas-listar"
}
