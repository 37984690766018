import { Injectable } from '@angular/core';
import { OfflineStorageService } from 'src/app/core/services/offline-storage.service';
import { VisitaOffline, VisitaOfflineCadastrarParams } from './models/visita';
import { LoteOffline } from '../models/lote';

@Injectable({
  providedIn: 'root'
})
export class VisitasOfflineService {

  constructor(
    private offlineStorageService: OfflineStorageService
  ) { }

  public delete(key: string): Promise<void> {
    return this.offlineStorageService.delete(key);
  }
  
  public async get(loteId: number, visitaId: number) {
    const lote: LoteOffline = await this.offlineStorageService.get(`LOTE_${loteId}`);

    const visita = lote.visitas.find((visitaLote) => visitaLote.id === visitaId) || null;

    return visita || null;
  }

  public editRequest(requestKey: string, url: string, visita: VisitaOfflineCadastrarParams) {
    return this.offlineStorageService.saveRequest(requestKey, url, 'POST', visita);
  }

  public async getAllFromLote(loteId: number): Promise<VisitaOffline[]> {
    const key_pattern = 'LOTE_' + loteId.toString();

    const visitasFromLote: VisitaOffline[] | null = (await this.offlineStorageService.get(key_pattern)).visitas || null;

    return visitasFromLote || [];
  }

  public async getVisitaRequest(requestKey: string): Promise<VisitaOfflineCadastrarParams> {
    const request = (await this.offlineStorageService.get(requestKey));

    return request == null ? null : { key: requestKey, url: request.url, ...request.data };
  }

  public async getAllVisitaRequestsFromLote(loteId: number): Promise<VisitaOfflineCadastrarParams[]> {
    let visitasFromRequests: VisitaOfflineCadastrarParams[] = [];

    (await this.offlineStorageService.getAll('REQUEST_')).forEach((request) => {
      if (request.url != null && request.url.indexOf('/visitas') > -1 && (request.data.loteId === loteId)) {
        visitasFromRequests.push({ key: request.key, url: request.url, ...request.data });
      }
    });

    return visitasFromRequests;
  }

  public deleteDocumento(documentoKey: string): Promise<void> {
    return this.offlineStorageService.delete(documentoKey);
  }

  public getDocumento(documentoKey: string): Promise<any> {
    return this.offlineStorageService.get(documentoKey);
  }

  public async getAllDocumentos(visitaId: number | string): Promise<any[]> {
    const key_pattern = 'DOCUMENTO_VISITA_' + visitaId + '_ID_';
    const documentosFromVisita = await this.offlineStorageService.getAll(key_pattern);

    return documentosFromVisita;
  }

  public async getDocumentoRequest(requestKey: string): Promise<any> {
    const request = (await this.offlineStorageService.get(requestKey));

    return request == null ? null : { key: requestKey, url: request.url, ...request.data };
  }

  public async getAllDocumentoRequests(visitaKey: string): Promise<any[]> {
    let documentosFromRequests: any[] = [];

    (await this.offlineStorageService.getAll('REQUEST_')).forEach((request) => {
      if (request.url == null)
        return;

      const indexOfVisitas = request.url.indexOf('/visitas/');
      const indexOfDocumentos = request.url.indexOf('/documentos');

      if (indexOfVisitas == null || indexOfDocumentos == null)
        return;

      const endOfVisitas = '/visitas/'.length;

      const requestVisitaKey = request.url.substring(indexOfVisitas + endOfVisitas, indexOfDocumentos);

      if (indexOfVisitas > -1 && indexOfDocumentos > -1 && (requestVisitaKey === visitaKey)) {
        documentosFromRequests.push({ key: request.key, url: request.url, ...request.data });
      }
    });

    return documentosFromRequests;
  }

  //
  // No momento o agente não possui o id,
  // portanto o nome de usuário está sendo usando como identificador
  //
  public saveAgente(agente: any): Promise<any> {
    const newAgente = {
      //key: 'AGENTE_' + agente.id,
      key: 'AGENTE_' + agente.username,
      data: agente
    };

    return this.offlineStorageService.save(newAgente);
  }

  public async getAgentes(): Promise<any[]> {
    return this.offlineStorageService.getAll('AGENTE_');
  }

}
