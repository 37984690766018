import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-current-environment',
  templateUrl: './current-environment.component.html',
  styleUrls: ['./current-environment.component.scss']
})
export class CurrentEnvironmentComponent {
  private _currentEnvironment: string = environment.name;

  constructor() {}

  ngOnInit(): void {
    console.log(this.currentEnvironment);

    if (this._currentEnvironment === 'DES') {
      this._currentEnvironment = 'de desenvolvimento';
    } else if (this._currentEnvironment === 'HOM') {
      this._currentEnvironment = 'de homologação';
    } else if (this._currentEnvironment === 'LOCAL') {
      this._currentEnvironment = 'local';
    }
  }

  public get currentEnvironment(): string {
    return this._currentEnvironment;
  }
}
