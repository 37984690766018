<div class="pro-page">

    <div class="pro-page-header">
        <h1 class="pro-title">Confirme o usuário</h1>
        <div class="pro-actions"> 
        </div>
    </div>

    <div class="pro-page-content listar-lotes">
        <div class="pro-page-section">
            <form class="pro-form cadastrar-visita not-inline" [formGroup]="form">
  
                <div class="grid w-full">
          
                  <div class="pro-form-item col-12 username required">
                    <label for="username">Nome de Usuário</label>
                    <input type="text" oninput="this.value=this.value.toLowerCase()" class="pro-inputtext" formControlName="username" pInputText id="username" autocomplete="one-time-code" required />

                    <small class="pro-feedback violation" *ngIf="submitted && form.controls['username'].errors?.['required']">O nome de usuário é obrigatório</small>
                    <small class="pro-feedback violation" *ngIf="submitted && form.controls['username'].errors?.['invalid-username']">O nome de usuário não confere com o usuário atual</small>
                  </div>
          
                </div>
          
                <div class="pro-form-submit">
                    <!-- O $event.preventDefault() faz com que os dados passados na URL não sejam interpretados como parâmetros, evitando
                        que seja adicionado um ponto de interrogação à URL quando offline (que faz com que a página não seja encontrada) -->
                    <button class="pro-button" pButton type="submit" [disabled]="submitted && !form.valid" (click)="$event.preventDefault(); save()">Confirmar</button>
                </div>
          
              </form>    
        </div>

    </div>

</div>
