import { Injectable, Type } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { ModalConfirmationComponent } from '../components/modal-confirmation/modal-confirmation.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private ref: DynamicDialogRef = {} as DynamicDialogRef;

  private defaultDialogDisplayConfig = {
    width: 'fit-content',
    // height: 'fit-content',
    contentStyle: { 'overflow': 'none' },
    baseZIndex: 10000,
    maximizable: false,
    closable: false,
    modal: true
  } as const;
  
  private customStyle = {
    'max-width': '100%'
  };

  constructor(private _dialogService: DialogService) { }
  
  public confirm( { message = '',     header = '',      severity = 'primary', confirmButtonLabel = 'Confirmar',   cancelButtonLabel = 'Cancelar' } : 
                  { message?: string, header?: string,  severity?:string,     confirmButtonLabel?: string,        cancelButtonLabel?: string }): Observable<any> {
    
    const data = {
      message: message,
      header: header,
      severity: severity,
      confirmButtonLabel: confirmButtonLabel,
      cancelButtonLabel: cancelButtonLabel
    };

    const config = {
      data: data,
      style: this.customStyle,
      ...this.defaultDialogDisplayConfig
    };

    this.ref = this._dialogService.open(ModalConfirmationComponent, config);

    return this.ref.onClose;
  }

  public open(component: Type<any>, componentData: any | null = null): Observable<any> {
    
    const data = componentData;

    const config = {
      data: data,
      style: this.customStyle,
      ...this.defaultDialogDisplayConfig
    };

    this.ref = this._dialogService.open(component, config);

    return this.ref.onClose;
  }

}
