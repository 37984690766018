import { Component, OnInit } from '@angular/core';
import { User } from '../../auth/user'; 
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';
import { MessageService } from 'primeng/api';
import { faCity, faHouse, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { Role } from '../../auth/roles.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  currentUser: User = {} as User;

  private _subscription: Subscription = new Subscription();

  // Icons
  faCity = faCity;
  faExit = faRightFromBracket;
  faHouse = faHouse;

  constructor(private _authService: AuthenticationService,
              private _messageService: MessageService) {}

  ngOnInit(): void {
    this._subscription = this._authService.getUser().subscribe({
      next: (user) => {
        this.currentUser = user;
      },
      error: (error) => {
        this._messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Obter usuário do Keycloak',
          detail: 'Erro ao obter usuário do keycloak: ' + error.message
        });
      }
    });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public logout(): void {
    this._authService.logout();
  }

  public userContainsRole(roleName: keyof typeof Role): boolean {
    const containsRole = Boolean(this.currentUser.roles?.includes(Role[roleName]));

    return containsRole;
  }

}
