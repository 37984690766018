import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './core/pages/page-not-found/page-not-found.component';
import { PageForbiddenComponent } from './core/pages/page-forbidden/page-forbidden.component';
import { RoleGuard } from './core/auth/role.guard';
import { ShareTargetComponent } from './core/pages/share-target/share-target.component';
import { PageConfirmUsernameComponent } from './core/pages/page-confirm-username/page-confirm-username.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'nucleos-urbanos' },
  { path: 'home', pathMatch: 'full', redirectTo: 'nucleos-urbanos' },

  { path: 'nucleos-urbanos', canActivate: [RoleGuard], loadChildren: () => import('./nucleos-urbanos/nucleos-urbanos.module').then(m => m.NucleosUrbanosModule) },
  { path: 'lotes', canActivate: [RoleGuard], loadChildren: () => import('./lotes/lotes.module').then(m => m.LotesModule) },

  //
  // ROTA UTILIZADA PELO SHARE_TARGET PARA ENVIAR ARQUIVOS AO COMPARTILHAR COM A APLICAÇÃO

  { path: 'share-target', pathMatch: 'full', component: ShareTargetComponent },
  { path: 'confirm-username', pathMatch: 'full', component: PageConfirmUsernameComponent },


  { path: '**', pathMatch: 'full', component: PageNotFoundComponent },
  { path: 'nao-autorizado', pathMatch: 'full', component: PageForbiddenComponent }
];

@NgModule({
  // imports: [RouterModule.forRoot(routes, {useHash: true})],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
