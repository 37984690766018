import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { NetworkStatusService } from '../services/network-status.service';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard extends KeycloakAuthGuard {
  constructor(
    protected override router: Router,
    protected override keycloakAngular: KeycloakService,
    private authService: AuthenticationService,
    private networkStatusService: NetworkStatusService
  ) {
    super(router, keycloakAngular);
  }

  isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {

      //return resolve(true);

      //
      //
      // No momento, quando offline o usuário sempre consegue acessar o sistema

      // if (this.networkStatusService.getConnectionStatus() !== true) {
      //   return resolve(true);
      // }

      //
      //  OBRIGA USUÁRIO A LOGAR NOVAMENTE APÓS x TEMPO
      // QUANDO OFFLINE E PEDE PARA CONFIRMAR USERNAME

      const currentTime = new Date().getTime();

      const oldTime = localStorage.getItem('currentUserTimeStamp');

      const MAXLOGINTIME = (4 * 60 * 60 * 1000); // 4 horas

      if (oldTime && (currentTime - parseInt(oldTime) > MAXLOGINTIME)) {
        this.router.navigateByUrl('/confirm-username');

        resolve(false);
      }

      let permission;
      if (!this.authenticated) {
        // this.keycloakAngular.login().catch((e) => console.error(e));
        return reject(false);
      }

      const requiredRoles: string[] = route.data['roles'];
      if (!requiredRoles) {
        permission = true;
      } else {
        permission = requiredRoles.every(
          (role) => this.roles && this.roles.includes(role)
        );
      }
      if (!permission) {
        this.router.navigateByUrl('/nao-autorizado');
      }
      resolve(permission);
    });
  }
}
