import { Component } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { Subscription } from 'rxjs';
import { ModalService } from '../../services/modal.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-modal-update',
  templateUrl: './modal-update.component.html',
  styleUrls: ['./modal-update.component.scss']
})
export class ModalUpdateComponent {

  private subscription: Subscription = new Subscription();
  private _blockUI: boolean = false;

  constructor(private swUpdate: SwUpdate, modalService: ModalService, messageService: MessageService) {
    this.subscription = this.swUpdate.versionUpdates.subscribe({
      next: ($event: VersionEvent) => {

        if ($event.type === 'VERSION_READY') {
          this._blockUI = true;

          modalService.confirm({
            message:  'Há uma <span>nova versão da página disponível</span>, deseja <span>atualizar agora?</span>' +
                      '<small>Ao aceitar a página será atualizada e a nova versão será carregada.</small>',
            header: 'ATUALIZAÇÃO DISPONÍVEL',
            severity: 'alert',
            cancelButtonLabel: 'Ignorar',
            confirmButtonLabel: 'Atualizar'
          }).subscribe(
            (response) => {
              if (response === 'ACCEPT') {
                window.location.reload();
              }

              this._blockUI = false;
            }
          );
        } else if ($event.type === 'VERSION_INSTALLATION_FAILED') {
          console.error($event)

          messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: 'Erro ao instalar nova versão do aplicativo'
          });
        }
        
      }
    });
  }

  public get blockUI(): boolean {
    return this._blockUI;
  }

}
