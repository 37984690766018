<div class="flex align-items-center flex-row w-full">
    <div class="home">
        <span><a [routerLink]="['/']" (click)="$event.preventDefault();" title="Home"><fa-icon [icon]="faHouse"></fa-icon></a></span>
    </div>
    
    <div class="main-info">
        <div class="logo text-center">
            <fa-icon [icon]="faCity"></fa-icon>
            <span class="name"><em>REURB</em> Digital</span>
            <span class="version">em Campo</span>
        </div>
        
        <div class="auth flex justify-content-center text-center">
            <span class="user-name">{{ currentUser.nome }}</span>
        </div>
    </div>

    <div class="logout">
        <span><a href="#" (click)="$event.preventDefault(); logout();" title="Logout"><fa-icon [icon]="faExit"></fa-icon></a></span>
    </div>
</div>
