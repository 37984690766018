import { Injectable } from '@angular/core';
import { OfflineStorageService } from '../core/services/offline-storage.service';
import { LoteCompleto, LoteOffline } from './models/lote';
import { AnotacaoCadastrarParamsOffline, AnotacaoOffline } from './models/anotacao';

@Injectable({
  providedIn: 'root'
})
export class LotesOfflineService {

  constructor(
    private offlineStorageService: OfflineStorageService
  ) { }

  public save(lote: LoteCompleto): Promise<LoteOffline> {
    let newLote = {
      key: 'LOTE_' + lote.id,
      data: lote
    };

    return this.offlineStorageService.save(newLote);
  }

  public delete(loteId: number): Promise<void> {
    const key =  'LOTE_' + loteId.toString();
    return this.offlineStorageService.delete(key);
  }

  public async get(loteId: number | string): Promise<LoteOffline> {
    const key =  'LOTE_' + loteId.toString();
    return this.offlineStorageService.get(key);
  }

  public async getAll(): Promise<LoteOffline[]> {
    return this.offlineStorageService.getAll('LOTE_');
  }

  public async getAllFromNucleoUrbano(nucleoUrbanoId: number): Promise<LoteOffline[]> {
    const lotes = await this.offlineStorageService.getAll('LOTE_');

    let lotesFromNucleoUrbano: any[] = [];

    lotes.forEach((lote) => {
      if (lote.nucleoUrbanoId === nucleoUrbanoId) {
        lotesFromNucleoUrbano.push(lote);
      }
    });

    return lotesFromNucleoUrbano;
  }

  //
  // DOCUMENTOS
  //

  public async getAllDocumentos(loteKey: string): Promise<any[]> {
    const key_pattern = 'DOCUMENTO_LOTE_' + loteKey.toString() + '_ID_';
    const documentosFromLote = await this.offlineStorageService.getAll(key_pattern);

    return documentosFromLote;
  }

  public saveDocumento(loteKey: string, documento: any): Promise<any> {
    let newDocumentoVisita = {
      key: 'DOCUMENTO_LOTE_' + loteKey + '_ID_' + documento.id,
      data: documento
    };

    return this.offlineStorageService.save(newDocumentoVisita);
  }

  public deleteDocumento(documentoKey: string): Promise<any> {
    return this.offlineStorageService.delete(documentoKey);
  }

  public async getDocumentoRequest(requestKey: string): Promise<any> {
    const request = (await this.offlineStorageService.get(requestKey));

    return request == null ? null : { key: requestKey, ...request.data };
  }

  public async getAllDocumentoRequests(loteKey: string): Promise<any[]> {
    let documentosFromRequests: any[] = [];

    (await this.offlineStorageService.getAll('REQUEST_')).forEach((request) => {
      if (request.url == null)
        return;

      const indexOfLotes = request.url.indexOf('/lotes/');
      const indexOfDocumentos = request.url.indexOf('/documentos');

      if (indexOfLotes == null || indexOfDocumentos == null)
        return;

      const endOfLotes = '/lotes/'.length;

      const requestLoteKey = request.url.substring(indexOfLotes + endOfLotes, indexOfDocumentos);

      console.log(requestLoteKey);

      if (loteKey.includes('LOTE')) {
        const parts = loteKey.split('_')
        loteKey = parts[parts.length - 1];
      }

      // console.log(`loteKey: ${loteKey}, requestLoteKey: ${requestLoteKey}, loteKey === requestLoteKey ? ${requestLoteKey === String(loteKey)}`)

      // console.log(`indexOfLotes: ${indexOfLotes}, indexOfDocumentos: ${indexOfDocumentos}`)

      if (indexOfLotes > -1 && indexOfDocumentos > -1 && (requestLoteKey === String(loteKey))) {
        documentosFromRequests.push({ key: request.key, ...request.data });
      }
    });

    return documentosFromRequests;
  }

  //
  // BUSCAR FOTO DO LOTE OFFLINE

  public async buscarFotoLote(loteKey: string): Promise<any> {
    let fotoLoteRequest: any = null;

    const offlineRequests = await this.offlineStorageService.getAll('REQUEST_');

    for (const request of offlineRequests) {
      if (request.url == null)
        continue;

      const indexOfLotes = request.url.indexOf('/lotes/');
      const indexOfDocumentos = request.url.indexOf('/documentos');

      if (indexOfLotes == null || indexOfDocumentos == null)
        continue;

      const endOfLotes = '/lotes/'.length;

      const requestLoteKey = request.url.substring(indexOfLotes + endOfLotes, indexOfDocumentos);

      console.log(requestLoteKey);

      if (loteKey.includes('LOTE')) {
        const parts = loteKey.split('_')
        loteKey = parts[parts.length - 1];
      }

      if (indexOfLotes < 0 || indexOfDocumentos < 0 || (requestLoteKey !== String(loteKey)))
        continue;

      const requestData = { key: request.key, ...request.data };

      if (requestData.tipoDocumento && requestData.tipoDocumento === 'FOTO_DO_LOTE') {
        fotoLoteRequest = requestData;
        break;
      }
    }

    return fotoLoteRequest;
  }

  //
  // ANOTAÇÕES
  //

  public deleteAnotacao(key: string): Promise<any> {
    return this.offlineStorageService.delete(key);
  }

  public async getAnotacoesFromLote(loteId: number): Promise<AnotacaoOffline[]> {
    const key_pattern = 'LOTE_' + loteId.toString();

    const anotacoes: AnotacaoOffline[] | null = (await this.offlineStorageService.get(key_pattern)).anotacoes || null;

    return anotacoes || [];
  }

  public async getAnotacaoRequestsFromLote(loteId: number): Promise<AnotacaoCadastrarParamsOffline[]> {
    let anotacoesFromRequests: AnotacaoCadastrarParamsOffline[] = [];

    (await this.offlineStorageService.getAll('REQUEST_')).forEach((request) => {
      if (request.url != null && request.url.indexOf('/anotacoes') > -1 && (request.data.loteId === loteId)) {
        anotacoesFromRequests.push({ key: request.key, ...request.data });
      }
    });

    return anotacoesFromRequests;
  }

  //
  // No momento o agente não possui o id,
  // portanto o nome de usuário está sendo usando como identificador
  //
  public saveAgente(agente: any): Promise<any> {
    const newAgente = {
      //key: 'AGENTE_' + agente.id,
      key: 'AGENTE_' + agente.username,
      data: agente
    };

    return this.offlineStorageService.save(newAgente);
  }

  public async getAgentes(): Promise<any[]> {
    return this.offlineStorageService.getAll('AGENTE_');
  }

}
