<div class="modal-container">
    
    <p-panel class="container-panel">

        <ng-template pTemplate="header">
            <div [ngClass]="severityClass">
                <h3>{{header}}</h3>
            </div>
        </ng-template>

        <div class="modal-body">
            <p [innerHTML]="message"></p>
        </div>

        <div class="modal-footer">
            <button class="pro-button tertiary" pButton type="button" (click)="cancelar()">{{cancelButtonLabel}}</button>
            <button #confirmButton class="pro-button" [ngClass]="severityClass" pButton type="button" (click)="confirmar()">
                {{confirmButtonLabel}}
            </button>
        </div>

    </p-panel>
    
</div>