import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VisitasService {

  URL = 'visitas';

  constructor(private http: HttpClient) { }

  //--------------------------------------------------------------------------------------------------------------
	//              VISITA
	//--------------------------------------------------------------------------------------------------------------

  //
  // CADASTRAR VISITA EM UM LOTE

  cadastrarVisita(visita: any, offline: boolean): Observable<any> {
    if (offline === true)
      return this.http.post<any>(this.URL, visita,{ headers: {offline: "true"} });
    else
      return this.http.post<any>(this.URL, visita);
  }
  
  //
  // LISTAR VISITAS POR QUERY

  listarVisitas(filteringParams: any): Observable<any> {
    let params: HttpParams = new HttpParams();

    if (filteringParams.loteId != null) params = params.append('loteId', filteringParams.loteId);

    console.log('log: Visitas GET request: ' + params);

    return this.http.get<any>(this.URL, { params: params });
  }

  //
  // BUSCAR VISITA

  buscarVisita(visitaId: number): Observable<any> {
    let urlWithParams: string = this.URL + '/' + visitaId.toString();

    return this.http.get<any>(urlWithParams);
  }

  //
  // EDITAR VISITA DE UM LOTE

  editarVisita(visitaId: number, visitaEditarParams: any): Observable<any> {
    let urlWithParams: string = this.URL + '/' + visitaId.toString();

    return this.http.put<any>(urlWithParams, visitaEditarParams);
  }

  //
  // REMOVER VISITA EM LOTE

  removerVisita(idVisita: number): Observable<any> {
    let urlWithParams: string = this.URL + '/' + idVisita.toString();

    return this.http.delete<any>(urlWithParams);
  }

  //
  // CADASTRAR NOVO DOCUMENTO EM VISITA

  cadastrarDocumentoVisita(visitaId: number | string, fileInfo: any, offline: boolean): Observable<any> {
    let urlWithParams: string = this.URL + '/' + visitaId + '/documentos';

    console.log('tentando enviar: ', fileInfo);

    if (offline === true) {
      return this.http.post<any>(urlWithParams, fileInfo,{ headers: {offline: "true"} });
    }

    let formData: FormData = new FormData();

    formData.append('nome', fileInfo.nome);
    formData.append('descricao', fileInfo.descricao);
    formData.append('file', fileInfo.file, fileInfo.nome);

    return this.http.post<any>(urlWithParams, formData);
  }

  //
  // LISTAR DOCUMENTOS DE UMA VISITA

  listarDocumentosVisita(idVisita: number, listarDocumentosVisitaParams: any): Observable<any> {
    let urlWithParams: string = this.URL + '/' + idVisita.toString() + '/documentos';

    let params: HttpParams = new HttpParams();

    if (listarDocumentosVisitaParams.exibirDeletados != null) params = params.append('exibirDeletados', listarDocumentosVisitaParams.exibirDeletados);

    return this.http.get<any>(urlWithParams, { params: params });
  }

  //
  // BUSCAR DOCUMENTO EM UMA VISITA

  buscarDocumentoVisita(idVisita: number, idDocumento: number): Observable<any> {
    let urlWithParams: string = this.URL + '/' + idVisita.toString() + '/documentos/' + idDocumento.toString();

    return this.http.get(urlWithParams);
  }

  //
  // REMOVER DOCUMENTO DE VISITA

  removerDocumentoVisita(visitaId: number, documentoId: number, motivo: string | undefined): Observable<any> {
    let urlWithParams: string = this.URL + '/' + visitaId.toString() + '/documentos/' + documentoId.toString();

    if (motivo) {
      return this.http.delete<any>(urlWithParams, { body:  { motivo: motivo } }); 
    }

    return this.http.delete<any>(urlWithParams);
  }

}
