import { Component } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { DynamicDialogComponent } from 'primeng/dynamicdialog';
import { Message, MessageService, PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'angular-pwa';

  constructor(private config: PrimeNGConfig) {
    //
    // FUNÇÃO PARA MODIFICAR O FILEUPLOAD PARA MOSTRAR OS TAMANHOS DOS ARQUIVOS
    // USANDO UNIDADES KB OU KiB
    
    FileUpload.prototype.formatSize = function (bytes) {
      if (bytes == 0) {
        return '0 B';
      }
      // 
      // Base para KB = 1000 / base para KiB = 1024
      // A unidade usada será KB

      let base: number = 1000;
      let casasDecimais: number = 3;
      let unidades: string[] = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      let indiceDaUnidadeAtual: number = Math.floor(Math.log(bytes) / Math.log(base));

      let numeroFormatado: string = (bytes / Math.pow(base, indiceDaUnidadeAtual)).toLocaleString(undefined, { maximumFractionDigits: casasDecimais }) + ' ' + unidades[indiceDaUnidadeAtual];

      return numeroFormatado;
    };

    //
    // Substitui a função de foco do DynamicDialog do PrimeNG por uma função vazia,
    // possibilitando controlar o foco manualmente
    // Caso contrário, o foco sempre vai para o primeiro elemento
    
    DynamicDialogComponent.prototype.focus = function() {}


    //
    // Modifica a função de add para deixar a mensagem do toast como "Sticky" por padrão

    const addPrototype = MessageService.prototype.add;

    function addWrapper(this: MessageService, message: Message) {
      if (message.sticky == null) {
        message.sticky = true;
      }

      addPrototype.apply(this, [message]);
    };

    MessageService.prototype.add = addWrapper;

    //
    // Muda a linguagem do calendário do PrimeNG

    this.config.setTranslation({
      apply: 'Aplicar',
      clear: 'Limpar',
      accept: 'Sim',
      reject: 'Não',
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
        'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje'
    });
  }

}
