import { Injectable } from '@angular/core';
import { OfflineStorageService } from '../core/services/offline-storage.service';
import { NetworkStatusService } from '../core/services/network-status.service';
import { NucleoUrbanoOffline } from './models/nucleo-urbano-offline';

@Injectable({
  providedIn: 'root'
})
export class NucleosUrbanosOfflineService {

  constructor(
    private offlineStorageService: OfflineStorageService,
    private networkStatusService: NetworkStatusService
  ) { }

  public save(nucleoUrbano: any): Promise<any> {
    let newNucleoUrbano = {
      key: 'NUI_' + nucleoUrbano.id.toString(),
      data: nucleoUrbano
    };

    return this.offlineStorageService.save(newNucleoUrbano);
  }

  public delete(nucleoUrbano: NucleoUrbanoOffline): Promise<void> {
    const key = 'NUI_' + nucleoUrbano.id;
    return this.offlineStorageService.delete(key);
  }

  public get(nucleoUrbanoId: number) {
    const key = 'NUI_' + nucleoUrbanoId;
    return this.offlineStorageService.get(key);
  }

  public async getAll(): Promise<NucleoUrbanoOffline[]> {
    return this.offlineStorageService.getAll('NUI_');
  }

}
