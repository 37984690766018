import { ErrorHandler } from '@angular/core';

export class AppErrorHandler extends ErrorHandler {

    constructor() {
        super();
    }

    override handleError(error: any) {
        super.handleError(error);
    }
    
}