import { Injectable } from '@angular/core';
import { OfflineStorageService } from 'src/app/core/services/offline-storage.service';
import { InteressadoOffline, InteressadoOfflineCadastrarParams } from './models/interessado';
import { LoteOffline } from '../models/lote';
import { DocumentoInteressadoCadastrar, DocumentoInteressadoCadastrarOffline, DocumentoInteressadoOffline } from './models/documento-interessado';

@Injectable({
  providedIn: 'root'
})
export class InteressadosOfflineService {

  constructor(
    private offlineStorageService: OfflineStorageService
  ) { }

  public delete(key: string): Promise<void> {
    return this.offlineStorageService.delete(key);
  }

  public async get(loteId: number, interessadoId: number): Promise<InteressadoOffline | null> {
    const lote: LoteOffline = await this.offlineStorageService.get(`LOTE_${loteId}`);

    const interessado = lote.interessados.find((interessadoLote) => interessadoLote.id === interessadoId) || null;

    return interessado || null;
  }

  public async getAllFromLote(loteId: number): Promise<InteressadoOffline[]> {
    const key_pattern = 'LOTE_' + loteId.toString();

    const interessadosFromLote: InteressadoOffline[] | null = (await this.offlineStorageService.get(key_pattern)).interessados || null;

    return interessadosFromLote || [];
  }

  public async getAllInteressadosRequests(): Promise<InteressadoOfflineCadastrarParams[]> {
    let interessadosRequests: InteressadoOfflineCadastrarParams[] = [];

    (await this.offlineStorageService.getAll('REQUEST_')).forEach((request) => {
      if (request.url != null && request.url.indexOf('/interessados') > -1) {
        interessadosRequests.push(request);
      }
    });

    return interessadosRequests;
  }

  public async getInteressadoRequest(requestKey: string): Promise<InteressadoOfflineCadastrarParams> {
    const request = (await this.offlineStorageService.get(requestKey));

    return request == null ? null : { key: requestKey, url: request.url, ...request.data };
  }

  public async getAllInteressadosRequestsFromLote(loteId: number): Promise<InteressadoOfflineCadastrarParams[]> {
    let interessadosRequests: InteressadoOfflineCadastrarParams[] = [];

    (await this.offlineStorageService.getAll('REQUEST_')).forEach((request) => {
      if (request.url != null && request.url.indexOf('/interessados') > -1 && (request.data.loteId === loteId)) {
        interessadosRequests.push({ key: request.key, url: request.url, ...request.data });
      }
    });

    return interessadosRequests;
  }

  public editRequest(requestKey: string, url: string, interessado: InteressadoOfflineCadastrarParams) {
    return this.offlineStorageService.saveRequest(requestKey, url, 'POST', interessado);
  }

  public deleteDocumento(documentoKey: string): Promise<void> {
    return this.offlineStorageService.delete(documentoKey);
  }

  public async getDocumento(loteId: number, interessadoId: number, documentoId: number): Promise<DocumentoInteressadoOffline | null> {
    const lote: LoteOffline = await this.offlineStorageService.get(`LOTE_${loteId}`);

    const interessado = lote.interessados.find((interessadoLote) => interessadoLote.id === interessadoId) || null;
    const documento = interessado?.documentos?.find((documentoInteressado) => documentoInteressado.id === documentoId) || null;

    return documento;
  }

  public async getAllDocumentos(loteId: number, interessadoId: number): Promise<DocumentoInteressadoOffline[]> {
    const lote: LoteOffline = await this.offlineStorageService.get(`LOTE_${loteId}`);

    const interessado = lote.interessados.find((interessadoLote) => interessadoLote.id === interessadoId) || null;
    const documentosInteressado = interessado?.documentos || [];

    return documentosInteressado;
  }

  public async getDocumentoRequest(requestKey: string): Promise<DocumentoInteressadoCadastrarOffline> {
    const request = (await this.offlineStorageService.get(requestKey));

    return request == null ? null : { key: requestKey, ...request.data };
  }

  public async getAllDocumentoRequests(interessadoKey: string): Promise<DocumentoInteressadoCadastrarOffline[]> {
    let documentosFromRequests: DocumentoInteressadoCadastrarOffline[] = [];

    (await this.offlineStorageService.getAll('REQUEST_')).forEach((request) => {
      if (request.url == null)
        return;

      const indexOfInteressados = request.url.indexOf('/interessados/');
      const indexOfDocumentos = request.url.indexOf('/documentos');

      if (indexOfInteressados == null || indexOfDocumentos == null)
        return;

      const endOfInteressados = '/interessados/'.length;

      const requestInteressadoKey = request.url.substring(indexOfInteressados + endOfInteressados, indexOfDocumentos);

      if (interessadoKey.includes('INTERESSADO')) {
        const parts = interessadoKey.split('_')
        interessadoKey = parts[parts.length - 1];
      }

      if (indexOfInteressados > -1 && indexOfDocumentos > -1 && (requestInteressadoKey === String(interessadoKey))) {
        documentosFromRequests.push({ key: request.key, url: request.url, ...request.data });
      }
    });

    return documentosFromRequests;
  }

}
