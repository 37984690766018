import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { EMPTY, Observable, of } from 'rxjs';
import { NetworkStatusService } from '../services/network-status.service';
import { AuthenticationService } from '../services/authentication.service';
import { OfflineStorageService } from '../services/offline-storage.service';

@Injectable()
export class OfflineInterceptor implements HttpInterceptor {

  constructor(private _offlineStorageService: OfflineStorageService,
              private _networkStatusService: NetworkStatusService,
              private _authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    // OFFLINE

    if (request.headers.get("offline")) {
      const headers = request.headers.delete('offline');
      const apiReq = request.clone({ headers: headers });

      console.log('INTERCEPTED REQUEST: ', apiReq);

        if (apiReq.method === 'POST') {
            const key: string = 'REQUEST_' + Math.random().toString();

            this._offlineStorageService.saveRequest(key, apiReq.urlWithParams, 'POST', apiReq.body);

            // return of(new HttpResponse({ status: 200, body: { info: 'saved_local', key: key } }));
            return of(new HttpResponse({ status: 200, body: key}));
        } else if (apiReq.method === 'DELETE') {
            const key = (apiReq.body as any).key;
            console.log(key);

            this._offlineStorageService.delete(key);

            return of(new HttpResponse({ status: 200 }));
        }

        return EMPTY;
    }

    return next.handle(request);
  }
}
