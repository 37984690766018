import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-confirm-username',
  templateUrl: './page-confirm-username.component.html',
  styleUrls: ['./page-confirm-username.component.scss']
})
export class PageConfirmUsernameComponent implements OnInit {

  submitted: boolean = false;
  form: FormGroup = new FormGroup('');

  constructor(private fb: FormBuilder,
              private router: Router) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      username: [ '', Validators.required ],
    });
  }

  public save(): void {
    this.submitted = true;

    if (this.form.valid) {
      const oldUsername = localStorage.getItem('currentUser');

      const username = this.form.value.username;

      if (username.toLowerCase() === oldUsername) {
        const currentTime = new Date().getTime();
        localStorage.setItem('currentUserTimeStamp', currentTime.toString());

        this.router.navigateByUrl('/');
      } else {
        this.form.get('username')?.setErrors({'invalid-username':true})
      }

    } else {
      this.markAllAsDirty(Object.values(this.form.controls));
    }
  }

  // Marca todos os campos como "modificados", mudando o estilo do formulário
  // conforme sua verificação
  //
  private markAllAsDirty(control: AbstractControl[]): void {
    control.forEach((control) => {
      if (control instanceof FormControl) {
        control.markAsDirty();
      } else if (control instanceof FormGroup) {
        this.markAllAsDirty(Object.values(control.controls));
      } else if (control instanceof FormArray) {
        this.markAllAsDirty(control.controls);
      }
    });
  }

}
