import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InteressadosService {

  URL = 'interessados';

  constructor(private http: HttpClient) { }

  //--------------------------------------------------------------------------------------------------------------
	//              INTERESSADO
	//--------------------------------------------------------------------------------------------------------------

  //
  // CADASTRAR INTERESSADO EM LOTE

  cadastrarInteressado(interessado: any, offline: boolean): Observable<any> {
    let urlWithParams: string = this.URL;
    if (offline === true)
      return this.http.post<any>(urlWithParams, interessado, { headers: {offline: "true"} });
    else
      return this.http.post<any>(urlWithParams, interessado);
  }

  //
  // LISTAR INTERESSADOS POR LOTE

  listarInteressadosPorLote(filteringParams: any): Observable<any> {
    let params: HttpParams = new HttpParams();

    if (filteringParams.size != null) params = params.append('size', filteringParams.size);
    if (filteringParams.loteIds != null) params = params.append('loteIds', filteringParams.loteIds.join(','));
    if (filteringParams.nome != null) params = params.append('nome', filteringParams.nome);
    if (filteringParams.vinculos != null) params = params.append('vinculos', filteringParams.vinculos.join(','));

    console.log('log: GET to interessados: ' + params);

    return this.http.get<any>(this.URL, { params: params });
  }

  //
  // BUSCAR INTERESSADO POR ID

  buscarInteressado(interessadoId: number): Observable<any> {
    let urlWithParams: string = this.URL + '/' + interessadoId.toString();

    return this.http.get<any>(urlWithParams);
  }

  //
  // EDITAR INTERESSADO DE UM LOTE

  editarInteressado(interessadoId: number, interessadoParams: any): Observable<any> {
    let urlWithParams: string = this.URL + '/' + interessadoId.toString();

    return this.http.put<any>(urlWithParams, interessadoParams);
  }

  //
  // REMOVER INTERESSADO DE UM LOTE

  removerInteressado(interessadoId: number): Observable<any> {
    let urlWithParams: string = this.URL + '/' + interessadoId.toString();

    return this.http.delete<any>(urlWithParams);
  }

  //
  // CADASTRAR NOVO DOCUMENTO EM INTERESSADO

  cadastrarDocumentoInteressado(interessadoId: number | string, fileInfo: any, offline: boolean): Observable<any> {
    let urlWithParams: string = this.URL + '/' + interessadoId.toString() + '/documentos';

    if (offline === true) {
      return this.http.post<any>(urlWithParams, fileInfo,{ headers: {offline: "true"} });
    }

    let formData: FormData = new FormData();

    formData.append('nome', fileInfo.nome);
    formData.append('descricao', fileInfo.descricao);
    formData.append('tipoDocumento', fileInfo.tipoDocumento);
    formData.append('file', fileInfo.file, fileInfo.nome);

    return this.http.post<any>(urlWithParams, formData);
  }

  //
  // LISTAR DOCUMENTOS DE UM INTERESSADO

  listarDocumentosInteressado(interessadoId: number, listarDocumentosInteressadoParams: any): Observable<any> {
    let urlWithParams: string = this.URL + '/' + interessadoId.toString() + '/documentos';

    let params: HttpParams = new HttpParams();

    if (listarDocumentosInteressadoParams.exibirDeletados != null) params = params.append('exibirDeletados', listarDocumentosInteressadoParams.exibirDeletados);

    return this.http.get<any>(urlWithParams, { params: params });
  }

  //
  // EDITAR DOCUMENTO DE INTERESSADO

  editarDocumentoInteressado(interessadoId: number, documentoId: number, editarDocumentoParams: any): Observable<any> {
    let urlWithParams: string = this.URL + '/' + interessadoId.toString() + '/documentos/' + documentoId.toString();

    return this.http.put<any>(urlWithParams, editarDocumentoParams);
  }

  //
  // BAIXAR DOCUMENTO DO INTERESSADO POR ID

  buscarDocumentoInteressado(interessadoId: number, idDocumento: number): Observable<any> {
    let urlWithParams: string = this.URL + '/' + interessadoId.toString() + '/documentos/' + idDocumento.toString();

    return this.http.get(urlWithParams);
  }

  //
  // REMOVER DOCUMENTO DE INTERESSADO

  removerDocumentoInteressado(interessadoId: number, documentoId: number, motivo: string | undefined): Observable<any> {
    let urlWithParams: string = this.URL + '/' + interessadoId.toString() + '/documentos/' + documentoId.toString();

    if (motivo) {
      return this.http.delete<any>(urlWithParams, { body:  { motivo: motivo } });
    }

    return this.http.delete<any>(urlWithParams);
  }

  //
  // LISTAR TIPOS DE DOCUMENTO DE INTERESSADO

  public listarTiposDocumentoInteressado(): Observable<any> {
    let urlWithParams: string = this.URL + '/tipos-documento';

    return this.http.get<any>(urlWithParams);
  }

  //
  // BUSCAR TIPOS DE VÍNCULO COM O IMÓVEL

  buscarTiposVinculoImovel(): Observable<any> {
    let urlWithParams: string = this.URL + '/vinculo-imovel';

    return this.http.get<any>(urlWithParams);
  }

  //
  // ALTERAR APTO PARA TITULAÇÃO

  alterarAptoParaTitulacao(interessadoId: number, aptoParaTitulacaoParams: any): Observable<any> {
    let urlWithParams = this.URL + '/' + interessadoId.toString() + '/apto-titulacao';

    return this.http.put<any>(urlWithParams, aptoParaTitulacaoParams);
  }

  //
  // CADASTRAR TELEFONE EM INTERESSADO

  cadastrarTelefoneInteressado(interessadoId: number, cadastrarTelefoneParams: any): Observable<any> {
    let urlWithParams = this.URL + '/' + interessadoId.toString() + '/telefones';

    return this.http.post<any>(urlWithParams, cadastrarTelefoneParams);
  }

  //
  // REMOVER TELEFONE EM INTERESSADO

  removerTelefoneInteressado(interessadoId: number, telefoneId: number): Observable<any> {
    let urlWithParams = this.URL + '/' + interessadoId.toString() + '/telefones/' + telefoneId.toString();

    return this.http.delete<any>(urlWithParams);
  }

  //
  // BUSCAR CIDADES

  listarMunicipios(): Observable<any> {
    let urlWithParams = this.URL + '/municipios';

    return this.http.get<any>(urlWithParams);
  }

}
