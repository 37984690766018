import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadedEntityService {

  private ENTITY_TYPE_STORAGE_NAME = 'loadedEntityType' as const;
  private NUCLEO_URBANO_ID_STORAGE_NAME = 'loadedNucleoUrbanoId' as const;
  private LOTE_ID_STORAGE_NAME = 'loadedLoteId' as const;
  private INTERESSADO_STORAGE_NAME = 'loadedInteressadoId' as const;
  private VISITA_ID_STORAGE_NAME = 'loadedVisitaId' as const;

  constructor() { }

  public lote(loteId: number | string, nucleoUrbanoId: number | string) {
    if (loteId === '' || (typeof loteId === 'number' && isNaN(loteId))) {
      throw Error('Id de lote inválido');
    }

    if (nucleoUrbanoId === '' || (typeof nucleoUrbanoId === 'number' && isNaN(nucleoUrbanoId))) {
      throw Error('Id de núcleo urbano inválido');
    }
    
    this.clear();

    const entityType = 'LOTE';
    
    localStorage.setItem(this.ENTITY_TYPE_STORAGE_NAME, entityType);
    localStorage.setItem(this.NUCLEO_URBANO_ID_STORAGE_NAME, nucleoUrbanoId.toString());
    localStorage.setItem(this.LOTE_ID_STORAGE_NAME, loteId.toString());
  }

  public interessado(loteId: number | string, nucleoUrbanoId: number | string, interessadoId: number | string): void {
    if (interessadoId === '' || (typeof interessadoId === 'number' && isNaN(interessadoId))) {
      throw Error('Id de interessado inválido');
    }

    if (loteId === '' || (typeof loteId === 'number' && isNaN(loteId))) {
      throw Error('Id de lote inválido');
    }

    if (nucleoUrbanoId === '' || (typeof nucleoUrbanoId === 'number' && isNaN(nucleoUrbanoId))) {
      throw Error('Id de núcleo urbano inválido');
    }

    this.clear();

    const entityType = 'INTERESSADO';
    
    localStorage.setItem(this.ENTITY_TYPE_STORAGE_NAME, entityType);
    localStorage.setItem(this.NUCLEO_URBANO_ID_STORAGE_NAME, nucleoUrbanoId.toString());
    localStorage.setItem(this.LOTE_ID_STORAGE_NAME, loteId.toString());

    localStorage.setItem(this.INTERESSADO_STORAGE_NAME, interessadoId.toString());
  }

  public visita(loteId: number | string, nucleoUrbanoId: number | string, visitaId: number | string): void {
    if (visitaId === '' || (typeof visitaId === 'number' && isNaN(visitaId))) {
      throw Error('Id de visita inválido');
    }

    if (loteId === '' || (typeof loteId === 'number' && isNaN(loteId))) {
      throw Error('Id de lote inválido');
    }

    if (nucleoUrbanoId === '' || (typeof nucleoUrbanoId === 'number' && isNaN(nucleoUrbanoId))) {
      throw Error('Id de núcleo urbano inválido');
    }

    this.clear();

    const entityType = 'VISITA';
    
    localStorage.setItem(this.ENTITY_TYPE_STORAGE_NAME, entityType);
    localStorage.setItem(this.NUCLEO_URBANO_ID_STORAGE_NAME, nucleoUrbanoId.toString());
    localStorage.setItem(this.LOTE_ID_STORAGE_NAME, loteId.toString());

    localStorage.setItem(this.VISITA_ID_STORAGE_NAME, visitaId.toString());
  }

  public clear(): void {
    localStorage.removeItem(this.ENTITY_TYPE_STORAGE_NAME);

    localStorage.removeItem(this.NUCLEO_URBANO_ID_STORAGE_NAME);
    localStorage.removeItem(this.LOTE_ID_STORAGE_NAME);
    localStorage.removeItem(this.INTERESSADO_STORAGE_NAME);
    localStorage.removeItem(this.VISITA_ID_STORAGE_NAME);
  }

  public get loadedEntityType(): string | null {
    const entityType = localStorage.getItem(this.ENTITY_TYPE_STORAGE_NAME);
    if (entityType) {
      return entityType;
    }

    return null;
  }

  public get loadedData() {
    const loadedEntity = this.loadedEntityType || null;
    const nucleoUrbanoId = localStorage.getItem(this.NUCLEO_URBANO_ID_STORAGE_NAME) || null;
    const loteId = localStorage.getItem(this.LOTE_ID_STORAGE_NAME) || null;
    const interessadoId = localStorage.getItem(this.INTERESSADO_STORAGE_NAME) || null;
    const visitaId = localStorage.getItem(this.VISITA_ID_STORAGE_NAME) || null;

    let loadedData = {
      loadedEntity: loadedEntity,
      nucleoUrbanoId: nucleoUrbanoId,
      loteId: loteId,
      interessadoId: interessadoId,
      visitaId: visitaId
    };

    return loadedData;
  }

}
